import { persianNumToEnglish } from "./GlobalConstants";

export const formPostPrepare = (model) => {
  let formData = new FormData();

  for (let key in model) {
    if (model[key] == null) continue;

    if (Array.isArray(model[key]) && model[key].length > 0) {
      if (model[key]["media"])
        formData.append(
          key,
          JSON.stringify(model[key].map((el) => el.media_id))
        );
      else formData.append(key, JSON.stringify(model[key]));
    } else if (typeof model[key] == "string" && model[key].length > 0)
      formData.append(key, model[key]);
    else if (typeof model[key] == "number" && model[key] != 0)
      // formData.append(key, model[key] - 1);
      formData.append(key, model[key]);
    else if (
      //file
      typeof model[key] == "object" &&
      model[key].name &&
      model[key].type
    ) {
      formData.append(key, model[key]);
    }
  }

  return formData;
};

export const formGetPrepare = (response, model) => {
  let res = response.data[0] ? response.data[0] : response.data;
  for (const key in model) {
    if (Array.isArray(res[key]) && res[key].length > 0) {
      // model[key] = JSON.parse(res[key]);
      model[key] = res[key];
    } else if (typeof res[key] == "number" || typeof res[key] == "object") {
      model[key] = res[key];
    }
    else if(typeof model[key] === 'boolean'){
      if(res[key] === "0" || res[key] === "1")
        model[key] = !!Number(res[key])
      else
        model[key] = !!res[key]
    }
    else if (key.includes("_id")) model[key] = +res[key];
    else if (res[key]) {
      model[key] = persianNumToEnglish(res[key]);
    }
  }
};
